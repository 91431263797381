html,
body {
  height: 100%;
  width: 100%;
}

.appbar-links {
  font-weight: 400;
  line-height: 1;
  display: inline-block;
  padding-left: 1.5rem;
  padding-top: 15px;
  color: #011f4b !important;
}

.appbar-links:hover {
  text-decoration: underline !important;
}

.navbar {
  background-color: #fafafa !important;
}

.api-info {
  padding-top: 100px !important;
}

a[href='https://redocly.com/redoc/']
{
  display: none !important;
}

.reachship-small-logo-auth-elex-caption {
  height: auto;
  width: 90px;
  padding-left: 10px;
  padding-bottom: 2px;
}